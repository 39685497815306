import { notifyError, notifySuccess } from "@subseq/web-management/lib/utility";
import { translate } from "@subseq/web-management/lib/translations";
var $addModal;
var $deleteModal;
var $changePwModal;
var $enabledModal;
$(function () {
    $addModal = $("#modal-add-user");
    $deleteModal = $("#modal-delete-user");
    $changePwModal = $("#modal-change-password");
    $enabledModal = $("#modal-set-enabled");
});
export function setEnabled() {
    submitModal("#enabled-form", "/api/user/set/active", function () {
        $enabledModal.modal("hide");
        var $form = $("#enabled-form");
        $form.find("input[name=user]").val();
        $form.find("input[name=active]").val();
        replaceContainer("#users");
    });
}
export function openEnabledModal(userId, isEnabled, btnReference) {
    var $form = $("#enabled-form");
    $form.find("input[name=user]").val(userId);
    $form.find("input[name=active]").val((!isEnabled).toString());
    var $enabledText = $("#enabled-text");
    var $modalButton = $("#modal-set-enabled-btn");
    if (isEnabled) {
        $enabledText.html(window._values.message["deactivate-user"]);
        $modalButton.html(window._values.action["disable"]);
        $modalButton.attr("class", "btn btn-danger");
    }
    else {
        $enabledText.html(window._values.message["activate-user"]);
        $modalButton.html(window._values.action["enable"]);
        $modalButton.attr("class", "btn btn-success");
    }
    $enabledModal.modal("show");
}
export function openNewUserModal() {
    var $form = $("#form-add-user");
    $form.find("input[name=name]").val("");
    $form.find("input[name=password]").val("");
    $addModal.modal("show");
}
export function createUser() {
    submitModal("#form-add-user", "/api/user/create", function () {
        $addModal.modal("hide");
        replaceContainer("#users");
    }, function (e) {
        notifyError(e.responseText);
    });
}
export function openDeleteUserModal(id) {
    var $form = $("#form-delete-user");
    $form.find("input[name=user]").val(id);
    $deleteModal.modal("show");
}
export function deleteUser() {
    submitModal("#form-delete-user", "/api/user/delete", function () {
        $deleteModal.modal("hide");
        replaceContainer("#users");
    });
}
export function openChangePasswordModal(id) {
    var $form = $("#form-change-password");
    $form.find("input[name=user]").val(id);
    $form.find("input[name=password]").val("");
    $("#cp-repeat-password").val("");
    $changePwModal.modal("show");
}
export function changePassword() {
    var pw = $("#cp-password");
    var repeatPw = $("#cp-repeat-password");
    if (pw.val() !== repeatPw.val()) {
        notifyError(translate("notification.password.dont-match"));
        return;
    }
    submitModal("#form-change-password", "/api/user/set/password", function (_) {
        $changePwModal.modal("hide");
        replaceContainer("#users");
    });
}
export function submitModal(formId, url, successCallback, errorCallback, method, showNotifications) {
    if (successCallback === void 0) { successCallback = function () { }; }
    if (errorCallback === void 0) { errorCallback = function () { }; }
    if (method === void 0) { method = "post"; }
    if (showNotifications === void 0) { showNotifications = true; }
    var form = $(formId);
    var formData = form.serialize();
    $.ajax({
        url: url,
        data: formData,
        method: method,
        success: function (data, textStatus, jqXHR) {
            if (showNotifications)
                notifySuccess();
            successCallback(data, textStatus, jqXHR);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (showNotifications)
                notifyError(errorThrown);
            errorCallback(jqXHR, textStatus, errorThrown);
        }
    });
    // $.post(url, formData, successCallback);
}
export function replaceContainer(containerId) {
    $.get("", function (data) {
        var content = $(data).find(containerId);
        $(containerId).replaceWith(content);
    });
}
