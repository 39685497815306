var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { notifyError, notifySuccess } from "@subseq/web-management/lib/utility";
export function replaceContainer(containerId) {
    $.get("", function (data) {
        var content = $(data).find(containerId);
        $(containerId).replaceWith(content);
    });
}
export function clearModal(modalSelector) {
    var $modal = $(modalSelector);
    var $form = $modal.find("form");
    var inputs = $form.find("input").get();
    var selects = $form.find("select").get();
    (__spreadArray(__spreadArray([], inputs, true), selects, true)).forEach(function (it) {
        it.value = "";
    });
}
export function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}
export function getLocationWithParam(param, value) {
    var search = window.location.search;
    return "".concat(window.location.protocol, "//").concat(window.location.host).concat(window.location.pathname).concat(setHttpParamsOnSearch(search, param, value));
}
export function setHttpParamsOnSearch(url, param, value) {
    var _a;
    var paramRegex = RegExp("[&?](".concat(param, "=.*?)(?:&|$)"));
    var match = (_a = url.match(paramRegex)) === null || _a === void 0 ? void 0 : _a[1];
    var result = "";
    if (match != null && match !== "") {
        var constructedParam = "".concat(param, "=").concat(value);
        result = url.replace(match, constructedParam);
    }
    else {
        result = "".concat(url).concat(url.includes("?") ? "&" : "?").concat(param, "=").concat(value);
    }
    return result;
}
export function setParamAndReload(param, value) {
    window.history.replaceState(null, null, setHttpParamsOnSearch(window.location.search, param, value));
    replaceContainer("#content");
}
export function setParamsAndReload(params) {
    window.location.search = Object.entries(params).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        return setHttpParamsOnSearch(acc, key, val);
    }, window.location.search);
    replaceContainer("#content");
}
export function submitGenericModal(formId, url, successCallback, errorCallback, showNotifications) {
    if (successCallback === void 0) { successCallback = function () { }; }
    if (errorCallback === void 0) { errorCallback = function () { }; }
    if (showNotifications === void 0) { showNotifications = true; }
    var form = $(formId);
    var formData = form.serialize();
    $.post(url, formData, function (t, u, v) {
        if (showNotifications)
            notifySuccess();
        successCallback(t, u, v);
    }).fail(function (t, u, v) {
        if (showNotifications)
            notifyError(v);
        errorCallback(t, u, v);
    });
}
export function submitGenericModalJSON(formId, url, successCallback, errorCallback, showNotifications) {
    var _this = this;
    if (successCallback === void 0) { successCallback = function () { }; }
    if (errorCallback === void 0) { errorCallback = function () { }; }
    if (showNotifications === void 0) { showNotifications = true; }
    var form = $(formId);
    var formData = form.serializeArray();
    var formDataJSON = Object.fromEntries(formData.map(function (it) { return ([it.name, it.value]); }));
    console.log(formDataJSON, JSON.stringify(formDataJSON));
    fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataJSON)
    }).then(function (r) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!r.ok) return [3 /*break*/, 3];
                    errorCallback(r);
                    if (!showNotifications) return [3 /*break*/, 2];
                    _a = notifyError;
                    return [4 /*yield*/, r.text()];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    _b.label = 2;
                case 2: return [2 /*return*/];
                case 3:
                    if (showNotifications)
                        notifySuccess();
                    successCallback(r);
                    return [2 /*return*/];
            }
        });
    }); }).catch(function (e) {
        console.error("Got error", e);
        if (showNotifications)
            notifyError(e.message);
        errorCallback(null);
    });
}
export function paramSetterFactory(param) {
    return function (value) { return setParamAndReload(param, value); };
}
export function navigateToPath(path, container) {
    if (container === void 0) { container = "#content"; }
    window.history.pushState(null, null, path);
    window.location.pathname = path;
    replaceContainer(container);
}
