import { translate } from "@subseq/web-management/lib/translations";
import { notifyError, notifySuccess } from "@subseq/web-management/lib/utility";
export function checkIfNameIsPermissible(id, name) {
    var formInputs = $("#" + id).find(':input').not(':input[type=button], :input[type=submit], :input[type=reset]');
    name = name.trim().toLowerCase();
    if (name.length === 0) {
        notifyError();
        return false;
    }
    if (name === "hook_url") {
        notifyError(translate("token.message.is-reserved"));
        return false;
    }
    if (name === "punchout_store_url") {
        notifyError("PUNCHOUT_STORE_URL ".concat(translate("token.message.is-reserved")));
        return false;
    }
    var isUnique = true;
    $.each(formInputs, function (key, value) {
        if (value.name.toLowerCase() === name) {
            isUnique = false;
        }
    });
    if (!isUnique) {
        notifyError(translate("token.message.label-unique-required"));
    }
    return isUnique;
}
export function load(token) {
    $.ajax({
        type: $(this).attr('post'),
        url: "/public/api/get/form?token=".concat(token),
        success: function (response) {
            if (response.response) {
                window.top.location.href = "/public/oci-test?token=".concat(token);
            }
            else {
                notifySuccess(translate("common.message.database.failed-load"));
            }
        },
        error: function (response) {
            notifyError(translate("common.message.database.failed-load"));
        }
    });
}
