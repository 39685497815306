import * as vkbeautify from "vkbeautify";
import { notifyError, notifyLoadFailed, notifySaveFailed, notifySuccess } from "@subseq/web-management/lib/utility";
import { translate } from "@subseq/web-management/lib/translations";
var responseStatusCode;
var generatedXmlBody;
var serverResponseBody;
$(function () {
    responseStatusCode = $("#response-status-code");
    generatedXmlBody = $("#generated-xml-body");
    serverResponseBody = $("#server-response-body");
});
var urlFieldName = "url";
var userFieldName = "httpuser";
var passwordFieldName = "httppassword";
export function rotateIcon(selector) {
    var el = $(selector);
    el.toggleClass("fa-rotate-180");
}
export function setStatusDisplay(code, text) {
    if (code === 200) {
        responseStatusCode.addClass("status-success");
    }
    else {
        responseStatusCode.addClass("status-error");
    }
    responseStatusCode.text(code + " (" + text + ")");
}
export function generateAndSend(template, token) {
    responseStatusCode.text("");
    generatedXmlBody.text("");
    serverResponseBody.text("");
    var formInputs = $("#xml-test-form")
        .find(":input")
        .not(':input[type=button], :input[type=submit], :input[type=reset]');
    var fields = {};
    formInputs.each(function (index, element) {
        fields[element.name] = element.value;
    });
    var action = $("#xml-test-url-input").val();
    var httpUser = $("#xml-test-httpuser-input").val();
    var httpPassword = $("#xml-test-httppassword-input").val();
    if (!action) {
        notifyError(translate("notification.no-endpoint"));
        return;
    }
    generatedXmlBody.text("Request send. Waiting for response!");
    serverResponseBody.text("Request send. Waiting for response!");
    $.ajax({
        type: 'POST',
        url: "/public/api/xml/generate?template=" + template + "&token=" + token + "&parameters=" + encodeURIComponent(JSON.stringify(fields)),
        success: function (response) {
            $("#response").slideDown();
            var xml = response.response.content;
            var formattedXml = vkbeautify.xml(xml);
            generatedXmlBody.text(formattedXml);
            $.ajax({
                type: 'POST',
                contentType: "application/vnd.c2api.v1+json",
                url: "/public/api/xml/post",
                data: JSON.stringify({
                    xml: xml,
                    httpUser: !!(httpUser.toString().trim()) ? httpUser.toString().trim() : null,
                    httpPassword: !!(httpPassword.toString().trim()) ? httpPassword.toString().trim() : null,
                    action: action,
                }),
                success: function (response, status, xhr) {
                    serverResponseBody.text(JSON.stringify(response, null, 2));
                },
                error: function (response, status, xhr) {
                    serverResponseBody.text(JSON.stringify(response, null, 2));
                    console.error("An error occurred, do not forget to check the console in case of CORS or other network violations.");
                },
                complete: function (xhr, textStatus) {
                    setStatusDisplay(xhr.status, textStatus);
                }
            });
        },
        error: function (response) {
            generatedXmlBody.text(response.toString());
        }
    });
    return false; // cancel original event to prevent form submitting
}
export function saveXmlForm(token) {
    var form = $("#xml-test-form");
    var data = form.serializeArray();
    data.push({ name: urlFieldName, value: $("#xml-test-url-input").val().toString() });
    data.push({ name: userFieldName, value: $("#xml-test-httpuser-input").val().toString() });
    data.push({ name: passwordFieldName, value: $("#xml-test-httppassword-input").val().toString() });
    console.log("Data:", data);
    $.ajax({
        type: "POST",
        url: "/public/api/xml/form/save?token=".concat(token),
        data: JSON.stringify(data),
        dataType: "json",
        contentType: 'application/json',
        success: function (response, status, xhr) {
            notifySuccess();
        },
        error: function (response, status, xhr) {
            notifySaveFailed();
        }
    });
}
export function loadXmlForm(token, suppressNotification) {
    if (suppressNotification === void 0) { suppressNotification = false; }
    $.ajax({
        type: "GET",
        url: "/public/api/xml/form/load?token=" + token,
        success: function (response, status, xhr) {
            console.log(response);
            if (response.response) {
                populate("#xml-test-form", JSON.parse(response.response.formValues));
                if (!suppressNotification) {
                    notifySuccess();
                }
            }
            else {
                if (!suppressNotification) {
                    notifyLoadFailed();
                }
            }
        },
        error: function (response, status, xhr) {
            if (!suppressNotification)
                notifyLoadFailed();
        }
    });
}
export function populate(frm, data) {
    $("#xml-test-url-input").val(data.filter(function (e) { return e.name === urlFieldName; })[0].value);
    $("#xml-test-httpuser-input").val(data.filter(function (e) { return e.name === userFieldName; })[0].value);
    $("#xml-test-httppassword-input").val(data.filter(function (e) { return e.name === passwordFieldName; })[0].value);
    data = data
        .filter(function (e) { return e.name !== urlFieldName; })
        .filter(function (e) { return e.name !== userFieldName; })
        .filter(function (e) { return e.name !== passwordFieldName; });
    data.forEach(function (field) {
        var name = field.name.replace(":", "\\:");
        $('[name=' + name + ']', frm).val(field.value);
    });
}
