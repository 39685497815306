import { updateTranslationsCache } from "@subseq/web-management/lib/translations";
import "./init";
export * from "@subseq/web-common";
import * as token_1 from "./tokens";
export { token_1 as token };
import * as faq_1 from "./faq-edit";
export { faq_1 as faq };
import * as users_1 from "./users";
export { users_1 as users };
import * as xmlTest_1 from "./xml-test";
export { xmlTest_1 as xmlTest };
import * as bcXml_1 from "./bc-xml";
export { bcXml_1 as bcXml };
import * as common_1 from "./common";
export { common_1 as common };
import * as oci_1 from "./oci";
export { oci_1 as oci };
import * as dns_1 from "./dns";
export { dns_1 as dns };
export { cachingUtil as caching } from "@subseq/web-management";
export * from "@subseq/web-management/lib/translations";
export * from "@subseq/web-management/lib/utility";
import * as lsCache_1 from "@subseq/web-management/lib/caching";
export { lsCache_1 as lsCache };
import * as dfns_1 from "./dfns";
export { dfns_1 as dfns };
import * as tables_1 from "@subseq/web-common/lib/tables";
export { tables_1 as tables };
import * as beautify_1 from "vkbeautify";
export { beautify_1 as beautify };
import * as highlight_1 from "./syntax-highlighting";
export { highlight_1 as highlight };
$(updateTranslationsCache);
