var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { replaceContainer } from "./users";
import { setHttpParamsOnSearch, setParamAndReload, setParamsAndReload } from "./common";
import { TableHierarchy } from "nested-datatables";
export var setFromDate = function (from) { return setParamsAndReload({
    "from": from,
    "page": "0"
}); };
export var setToDate = function (to) { return setParamsAndReload({
    "to": to,
    "page": "0"
}); };
export var setHideInvalid = function (value) {
    setParamsAndReload({
        "filterInvalid": value.toString(),
        "page": "0"
    });
};
export function setSorting(fieldName, direction) {
    var newSearch = window.location.search;
    newSearch = setHttpParamsOnSearch(newSearch, "sortField", fieldName);
    newSearch = setHttpParamsOnSearch(newSearch, "sortDirection", direction);
    window.history.replaceState(null, null, newSearch);
    replaceContainer("#content");
}
export function setPagination(page, show) {
    var newSearch = window.location.search;
    newSearch = setHttpParamsOnSearch(newSearch, "page", page.toString());
    newSearch = setHttpParamsOnSearch(newSearch, "show", show.toString());
    window.history.replaceState(null, null, newSearch);
    replaceContainer("#content");
}
export var setPage = function (page) { return setParamAndReload("page", page.toString()); };
export var setShow = function (show) { return setParamAndReload("show", show.toString()); };
function isOfPrimitiveType(data) {
    var dataType = typeof data;
    return dataType === "number" || dataType === "string";
}
function isPrimitiveObject(data) {
    return data instanceof Object && Object.values(data).filter(function (it) { return typeof it !== "string" && typeof it !== "number"; }).length === 0;
}
function isMixed(data) {
    var numberOfPrimitives = Object.values(data).filter(function (it) { return typeof it !== "string" && typeof it !== "number"; }).length;
    return data instanceof Object && numberOfPrimitives > 0 && numberOfPrimitives < Object.keys(data).length;
}
var ignoredKeys = ["BEGIN", "SEGMENT"];
export function convertJsonToNestedTableData(data) {
    ignoredKeys.forEach(function (it) { return delete data[it]; });
    console.log("DATA", data);
    // detect if something is a pure data-object, then return it
    if (isPrimitiveObject(data)) {
        return {
            data: data,
            kids: []
        };
    }
    else if (isMixed(data)) {
        var _a = Object.entries(data).reduce(function (acc, e) {
            acc[isOfPrimitiveType(e[1]) ? 0 : 1].push(e);
            return acc;
        }, [[], []]), primitiveEntries = _a[0], complexEntries = _a[1];
        return {
            data: Object.fromEntries(primitiveEntries),
            kids: convertJsonToNestedTableData(Object.fromEntries(complexEntries)).kids
        };
    }
    // Only complex objects from here-on out
    var entries = Object.entries(data).map(function (_a) {
        var key = _a[0], val = _a[1];
        if (Array.isArray(val)) {
            return {
                kids: val.map(function (it) { var r = convertJsonToNestedTableData(it); console.log(val, "Converted", it, "to", r); return r; }),
                data: {}
            };
        }
        else if (isPrimitiveObject(val)) {
            return {
                data: val,
                kids: []
            };
        }
        else {
            console.error("BRUH", data, key, val);
        }
    });
    return { kids: entries, data: {} };
}
/**
 * Takes data and converts it to a nested table
 * @param data BC-XML as json
 * @param id wrapper element-id (without #)
 */
export function processXmlVisualization(data, id) {
    var treeOrNull = (function () {
        var _a;
        try {
            var idoc = (_a = data["ORDERS01"]) === null || _a === void 0 ? void 0 : _a["IDOC"];
            if (!idoc)
                return;
            var EDI_DC40_1 = idoc.EDI_DC40, rest = __rest(idoc, ["EDI_DC40"]);
            if (!(EDI_DC40_1 && rest))
                return;
            ignoredKeys.forEach(function (it) { return delete EDI_DC40_1[it]; });
            return [
                {
                    data: EDI_DC40_1,
                    kids: []
                },
                convertJsonToNestedTableData(rest)
            ];
        }
        catch (e) {
            return null;
        }
    })();
    var settings = {
        "iDisplayLength": 20,
        "bLengthChange": false,
        "bFilter": false,
        "bSort": false,
        "bInfo": false,
        "paging": false
    };
    console.log(treeOrNull);
    if (!treeOrNull)
        return;
    var table = new TableHierarchy(id, treeOrNull, settings);
    table.initializeTableHierarchy();
}
