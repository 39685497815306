var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { replaceContainer, submitGenericModalJSON } from "./common";
import { notifyError, notifySuccess } from "@subseq/web-management/lib/utility";
import "@popperjs/core";
export function clearModal(modalSelector) {
    var $modal = $(modalSelector);
    var $form = $modal.find("form");
    var inputs = $form.find("input").get();
    var areas = $form.find("textarea").get();
    var selects = $form.find("select").get();
    (__spreadArray(__spreadArray(__spreadArray([], inputs, true), selects, true), areas, true)).forEach(function (it) {
        it.value = "";
    });
}
export function openCopyModal(domainJsonString) {
    var $modal = $("#addDomainModal");
    var domainJson = JSON.parse(domainJsonString);
    clearModal("#addDomainModal");
    var $form = $modal.find("form");
    var $domainInput = $form.find("input[name='domain']");
    var $checkIntervalInput = $form.find("input[name='checkIntervalMinutes']");
    var $supplierUIDInput = $form.find("input[name='supplierUID']");
    var $supplierNameInput = $form.find("input[name='supplierName']");
    var $responsibleNameInput = $form.find("input[name='responsibleName']");
    var $responsibleEmailInput = $form.find("input[name='responsibleEmail']");
    var $commentInput = $form.find("textarea[name='comment']");
    $domainInput.val(domainJson.domain);
    $checkIntervalInput.val(domainJson.checkIntervalMinutes);
    $supplierUIDInput.val(domainJson.supplierUID);
    $supplierNameInput.val(domainJson.supplierName);
    $responsibleNameInput.val(domainJson.responsibleName);
    $responsibleEmailInput.val(domainJson.responsibleEmail);
    $commentInput.val(domainJson.comment);
    $modal.modal("show");
}
export function openEditModal(args) {
    var parsedArgs = JSON.parse(args);
    var $modal = $("#editDomainModal");
    var $form = $modal.find("form");
    var entries = Object.entries(parsedArgs);
    entries.forEach(function (_a) {
        var name = _a[0], value = _a[1];
        if (typeof value === "boolean") {
            $form.find("input[name=\"".concat(name, "\"]")).prop("checked", value).val(value.toString());
        }
        else {
            $form.find("input[name=\"".concat(name, "\"]")).val(value);
        }
    });
    $form.find('textarea[name="comment"]').val(parsedArgs.comment);
    $modal.modal("show");
}
export function submitDomainAdd() {
    var _this = this;
    var form = document.getElementById("addDomainForm");
    if (!form.reportValidity()) {
        return;
    }
    submitGenericModalJSON("#addDomainForm", "/public/api/dns/add-domain", function () {
        $("#addDomainModal").modal("hide");
        clearModal("#addDomainModal");
        replaceContainer("#domain-table");
        notifySuccess();
    }, function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(typeof e === "object")) return [3 /*break*/, 2];
                    _a = notifyError;
                    return [4 /*yield*/, e.text()];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [3 /*break*/, 3];
                case 2:
                    notifyError();
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, false);
}
export function submitDomainEdit() {
    var _this = this;
    var form = document.getElementById("editDomainForm");
    if (!form.reportValidity()) {
        return;
    }
    submitGenericModalJSON("#editDomainForm", "/public/api/dns/edit-domain", function () {
        $("#editDomainModal").modal("hide");
        clearModal("#editDomainModal");
        replaceContainer("#domain-table");
        notifySuccess();
    }, function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = notifyError;
                    return [4 /*yield*/, e.text()];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [2 /*return*/];
            }
        });
    }); }, false);
}
export function deleteEntry(id) {
    fetch("/public/api/dns/delete-domain/" + id, {
        method: "DELETE",
    }).then(function (r) {
        if (!r.ok) {
            throw Error();
        }
        console.log("SUCCESS DELETING", r);
        notifySuccess();
        replaceContainer("#domain-table");
    }).catch(function () { return notifyError(); });
}
export function openLogsModal(id) {
    $.get("/dns/domains/logs?id=".concat(id), function (data) {
        var content = $(data);
        var $logsContainer = $("#logs-table-container");
        $logsContainer.empty();
        $logsContainer.append(content);
    });
    $("#logsModal").modal("show");
}
export function resolveDomain(domainId) {
    return __awaiter(this, void 0, void 0, function () {
        var r, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch("/public/api/dns/resolve-domain/".concat(domainId))];
                case 1:
                    r = _b.sent();
                    if (!!r.ok) return [3 /*break*/, 3];
                    notifyError("500");
                    _a = Error.bind;
                    return [4 /*yield*/, r.text()];
                case 2: throw new (_a.apply(Error, [void 0, _b.sent()]))();
                case 3: return [2 /*return*/, r.json()];
            }
        });
    });
}
export function checkDomain(domainId) {
    return __awaiter(this, void 0, void 0, function () {
        var r, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch("/public/api/dns/check/".concat(domainId), { method: "POST" })];
                case 1:
                    r = _b.sent();
                    if (!!r.ok) return [3 /*break*/, 3];
                    notifyError("500");
                    _a = Error.bind;
                    return [4 /*yield*/, r.text()];
                case 2: throw new (_a.apply(Error, [void 0, _b.sent()]))();
                case 3:
                    notifySuccess("Refreshed");
                    return [2 /*return*/, r.json()];
            }
        });
    });
}
