import { DEFAULT_DATE_FORMAT } from "@subseq/web-common";
import { clearInputsIn, notifyError, reloadContainer } from "@subseq/web-management/lib/utility";
import dateAdd from "date-fns/esm/add";
import dateParse from "date-fns/esm/parse";
import dateFormat from "date-fns/esm/format";
import { getLocationWithParam, replaceContainer, submitGenericModal } from "./common";
import { translate } from "@subseq/web-management/lib/translations";
var $extensionForm;
var $editForm;
$(function () {
    $extensionForm = $("#extendTokenForm");
    $editForm = $("#editTokenForm");
});
export function clear() {
    $.get("/public/api/clear/session/token", null, function () {
        window.top.location.href = "/";
    });
}
export function changeFilter(target) {
    var checked = $(target).is(":checked");
    var href = window.location.href;
    var newHref = "";
    console.debug(checked);
    window.top.location.href = getLocationWithParam("filterInvalid", checked ? "true" : "false");
    replaceContainer("#content");
}
export var $copy = $(".copy-btn");
$copy.each(function () {
    $(this).popover({
        content: $(this).data("popover-content"),
        placement: $(this).data("popover-placement")
    });
    $(this).tooltip({
        title: $(this).data("tooltip-content"),
        placement: $(this).data("tooltip-placement"),
        trigger: "hover focus",
        delay: {
            show: 400,
            hide: 400
        }
    });
});
// Handle Copy-Button Popover event
$copy.on("shown.bs.popover", function (e) {
    var btn = $(e.target);
    setTimeout(function () {
        btn.popover("hide");
    }, 600);
});
$copy.on("show.bs.popover", function (e) {
    $(e.target).tooltip("hide");
});
export function openTokenExtensionModal(head, currentExpirationDate) {
    $extensionForm.find("input[name=head]").val(head);
    var currentValidUntil = dateParse(currentExpirationDate, DEFAULT_DATE_FORMAT, new Date());
    var dateString = dateFormat(currentValidUntil, DEFAULT_DATE_FORMAT);
    $extensionForm.find("input[name=validUntil").val(dateString);
    $("#extendTokenModal").modal("show");
}
export function openTokenEditModal(head, name, oci, xml) {
    $editForm.find("input[name=head]").val(head);
    $editForm.find("input[name=name]").val(name);
    $editForm.find("input[name=isOciTest]").prop("checked", oci);
    $editForm.find("input[name=isXmlTest]").prop("checked", xml);
    $("#editTokenModal").modal("show");
}
export function extendValidUntil(day, month) {
    var _a, _b;
    if (day === void 0) { day = 0; }
    if (month === void 0) { month = 0; }
    var dateString = (_b = (_a = $extensionForm.find("input[name=validUntil]")) === null || _a === void 0 ? void 0 : _a.val()) === null || _b === void 0 ? void 0 : _b.toString();
    var d = dateParse(dateString, DEFAULT_DATE_FORMAT, new Date());
    var extended = quickExtendDate(d, day, month);
    console.debug({ "Extended": extended, "Date": d, "Date-String": dateString });
    $extensionForm.find("input[name=validUntil]").val(dateFormat(extended, DEFAULT_DATE_FORMAT));
}
export function quickExtendDate(date, days, months) {
    if (days === void 0) { days = 0; }
    if (months === void 0) { months = 0; }
    return dateAdd(date, { days: days, months: months });
}
export function submitTokenExtension() {
    submitGenericModal("#extendTokenForm", "/api/testhead/extend", function (_) {
        $("#extendTokenModal").modal("hide");
        replaceContainer("#tokens");
    });
}
export function submitTokenEdit() {
    var name = $editForm.find("input[name=name]").val();
    if (name === "") {
        var errorMsg = translate("validation.oci.field-required-named").replace("{field}", "Name");
        console.log(errorMsg);
        notifyError(errorMsg);
        return;
    }
    submitGenericModal("#editTokenForm", "/api/testhead/edit", function (_) {
        $("#editTokenModal").modal("hide");
        replaceContainer("#tokens");
    });
}
export function reloadContainerAndCloseModal(modalId) {
    reloadContainer("#token-table", function (_) {
        $(modalId).modal("hide");
        clearInputsIn('${id}');
    });
}
export function invalidateToken(id) {
    $.get("/api/testhead/invalidate?head=" + id, null, function () {
        location.reload();
    });
}
export function openInvalidateModal(tokenId, modalId) {
    // Assign invalidateToken call to modal confirm action
    $(modalId + " .confirm").attr("onclick", "invalidateToken(" + tokenId + ")");
    $(modalId).modal("show");
}
