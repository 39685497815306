$(function () {
    $("[data-toggle=popover]").popover({ html: true, trigger: "hover" });
});
export function deleteEntry() {
    submitModal("#delete-entry-form", "/api/faq/delete/entry", function (data) {
        if (data.response === true) {
            replaceContainer("#faq");
            closeDeleteEntryModal();
        }
    });
}
export function deleteTopic() {
    submitModal("#delete-topic-form", "/api/faq/delete/topic", function (data) {
        if (data.response === true) {
            replaceContainer("#faq");
            closeDeleteTopicModal();
        }
    });
}
export function openDeleteEntryModal(entryId) {
    var $form = $("#delete-entry-form");
    var $modal = $("#entry-delete-modal");
    $form.find("input[name=entry]").val(entryId);
    $modal.modal("show");
}
export function openDeleteTopicModal(topicId) {
    var $form = $("#delete-topic-form");
    var $modal = $("#topic-delete-modal");
    $form.find("input[name=topic]").val(topicId);
    $modal.modal("show");
}
export function closeDeleteEntryModal() {
    var $form = $("#delete-entry-form");
    var $modal = $("#entry-delete-modal");
    $modal.modal("hide");
    $form.find("input[name=entry]").val("");
}
export function closeDeleteTopicModal() {
    var $form = $("#delete-topic-form");
    var $modal = $("#topic-delete-modal");
    $modal.modal("hide");
    $form.find("input[name=topic]").val("");
}
export function openChangeEntryModal(entryId, question, answer) {
    var $form = $("#change-entry-form");
    var $question = $form.find("input[name=question]");
    var $answer = $form.find("textarea[name=answer]");
    var $id = $form.find("input[name=entryId]");
    $question.val(question);
    $answer.val(answer);
    $id.val(entryId);
    $("#entry-edit-modal").modal("show");
}
export function closeChangeEntryModal() {
    $("#entry-edit-modal").modal("hide");
    var $form = $("#change-entry-form");
    var $question = $form.find("input[name=question]");
    var $answer = $form.find("textarea[name=answer]");
    var $id = $form.find("input[name=entryId]");
    $question.val("");
    $answer.val("");
    $id.val("");
}
export function openChangeTopicModal(topicId, topicName) {
    var $modal = $("#topic-edit-modal");
    var $form = $("#edit-topic-form");
    var $id = $form.find("input[name=id]");
    var $name = $form.find("input[name=name]");
    $id.val(topicId);
    $name.val(topicName);
    $modal.modal("show");
}
export function closeChangeTopicModal() {
    var $modal = $("#topic-edit-modal");
    var $form = $("#edit-topic-form");
    var $originalName = $form.find("input[name=originalName]");
    var $name = $form.find("input[name=name]");
    $modal.modal("hide");
    $originalName.val("");
    $name.val("");
}
export function changeTopic() {
    submitModal("#edit-topic-form", "/api/faq/edit/topic", function (data) {
        if (data.response === true) {
            replaceContainer("#faq");
            replaceContainer("#add-entry-form");
            closeChangeTopicModal();
        }
    });
}
export function closeAddTopicModal() {
    var $modal = $("#topic-add-modal");
    var $form = $("#add-topic-form");
    $modal.modal("hide");
    $form.find("input[name=name]").val("");
}
export function changeEntry() {
    submitModal("#change-entry-form", "/api/faq/edit/entry", function (data) {
        if (data.response === true) {
            replaceContainer("#faq");
            closeChangeEntryModal();
        }
    });
}
export function addEntry() {
    submitModal("#add-entry-form", "/api/faq/add/entry", function (data) {
        console.log(data);
        if (data.code === 200) {
            replaceContainer("#faq");
            closeAddEntryModal();
        }
    });
}
export function addTopic() {
    submitModal("#add-topic-form", "/api/faq/add/topic", function (data) {
        if (data.code === 200) {
            replaceContainer("#faq");
            replaceContainer("#add-entry-form");
            closeAddTopicModal();
        }
    });
}
export function closeAddEntryModal() {
    $("#entry-add-modal").modal("hide");
    var $form = $("#add-entry-form");
    var $question = $form.find("input[name=question]");
    var $answer = $form.find("textarea[name=answer]");
    $question.val("");
    $answer.val("");
}
export function submitModal(formId, url, successCallback) {
    var form = $(formId);
    var formData = form.serialize();
    $.get(url, formData, successCallback);
}
export function replaceContainer(containerId) {
    $.get("", function (data) {
        var content = $(data).find(containerId);
        $(containerId).replaceWith(content);
    });
}
